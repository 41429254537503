<template>
  <form @submit.prevent="saveReminder">
    <v-text-field
      :rules="[(v) => !!v || 'This field is required.']"
      label="Title"
      v-model="formData.title"
      placeholder="Name your reminder"
    />
    <v-textarea
      name="description"
      v-model="formData.description"
      label="Write a note to remind you"
      rows="2"
      hint="You'll see this note one the notification."
    />
    
    <h3 class="mb-0">
      Run at,
    </h3>
    <cron-editor v-model="formData.cron_data" />
    <!-- <v-text-field
      :rules="[(v) => !!v || 'This field is required.']"
      label="Reminder time"
      v-model="formData.cron_data"
      placeholder="Ex: */1 * * * *"
    /> -->
    
    <p
      class="mt-4 red pa-2 white--text"
      v-if="errorResponse"
    >
      {{ errorResponse }}
    </p>
    <v-btn
      type="submit"
      class="mt-4"
      :color="update ? 'info' : 'primary'"
    >
      {{ update ? 'Update' : 'Create' }}
    </v-btn>
  </form>
</template>

<script>
import CronEditor from '../common/CronEditor.vue'
import { findoutTheErrorMsg } from '@/utils/error-parser.js'

export default {
  name: "ReminderForm",
  components: { CronEditor },
  data () {
    return {
      formData: this.reminder || {type: 'general'},
      errorResponse: null
    }
  },
  props: {
    reminder: {
      type: Object,
      required: false,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async saveReminder () {
      const response = await this.$store.dispatch("createOrUpdateReminderAction", this.formData)
      if (response && (response.status === 201 || response.status === 200)) {
        this.formData = {}
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.$emit("closeModal")
      } else {
        this.errorResponse = findoutTheErrorMsg(response.data)
      }
    }
  },
  watch: {
    'reminder.id' (newValue) {
      if (newValue) {
        this.formData = JSON.parse(JSON.stringify(this.reminder))
      }
    }
  }
}
</script>