<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>{{ reminder.title }}</v-card-title>
      <v-divider />
      <v-card-text>
        <!-- Loads the tabs here -->
        <v-tabs v-model="reminderTab">
          <v-tab>Details</v-tab>
        </v-tabs>
        <!-- Loads the tabs value here... -->
        <v-tabs-items
          v-model="reminderTab"
          centered
        >
          <v-tab-item>
            <key-value-pair-data
              :data="reminder"
              :date-time-fields="dateTimeFields"
              :ignored-fields="ignoredFieldsToShow"
              :sort="true"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          class="white--text"
          color="info"
          small
          @click="$emit('onEditButtonPress', reminder)"
        >
          <v-icon small>
            mdi-square-edit-outline
          </v-icon>
          Update
        </v-btn>
        <v-btn
          class="white--text"
          color="red"
          small
          @click="$emit('onDeleteButtonPress', reminder)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyValuePairData from '../common/KeyValuePairData.vue'

export default {
  components: { KeyValuePairData },
  name: "ReminderDetailsModal",
  props: {
    reminder: {
      type: Object,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      ignoredFieldsToShow: ["id", "author", "task_key", "cron_data"],
      dateTimeFields: ["created_at", "updated_at"],
      reminderTab: null
    }
  },
  watch: {
    openModal(newValue) {
      this.open = newValue
    },
    open(newValue) {
      if (newValue === false) {
        this.$emit("modalClosed")
      }
    }
  }
}
</script>