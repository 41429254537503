<template>
  <div class="reminder-list">
    <!-- External components -->
    <reminder-details-modal
      :reminder="selectedReminder"
      :open-modal="detailModal"
      @modalClosed="closeDetailsModal"
      @onEditButtonPress="(_remind) => (selectedReminder = _remind, createUpdateModal = true, isUpdate = true)"
      @onDeleteButtonPress="(_remind, _status) => deleteReminder(_remind, _status)"
    />
    <reminder-form-modal
      :reminder="selectedReminder"
      :open-modal="createUpdateModal"
      :update="isUpdate"
      @modalClosed="createUpdateModal = false, selectedReminder = {}, isUpdate = false, detailModal = false, adjustForm = false"
    />
    <my-alert ref="confirm" />
    <v-btn
      dark
      fixed
      bottom
      right
      fab
      class="mb-8 mr-5"
      color="primary"
      @click="createUpdateModal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-list>
      <h1 class="title">
        Reminders
      </h1>
      <v-divider />
      <p
        class="text-center pt-5"
        v-if="reminders.length === 0"
      >
        No data found.
      </p>
      <v-list-item
        v-for="reminder in reminders"
        :key="reminder.id"
      >
        <v-list-item-avatar>
          <v-icon
            class="brown lighten-1"
            dark
          >
            mdi-bell
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content
          class="reminder-content"
          @click="openDetailsModal(reminder)"
        >
          <v-list-item-title>{{ reminder.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ translateCronExpression(reminder.cron_data) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            small
            icon
            color="info"
            @click.prevent="openEditModal(reminder)"
          >
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn
            small
            icon
            color="red"
            @click.prevent="deleteReminder(reminder)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>


<script>
import ReminderDetailsModal from './ReminderDetailsModal.vue'
import ReminderFormModal from './ReminderFormModal.vue'
import MyAlert from "@/components/common/MyAlert"
import cronstrue from 'cronstrue';

export default {
  components: { ReminderDetailsModal, ReminderFormModal, MyAlert },
  name: "ReminderList",
  data() {
    return {
      selectedReminder: {},
      detailModal: false,
      adjustForm: false,
      createUpdateModal: false,
      isUpdate: false,
      loading: false
    }
  },
  computed: {
    reminders() {
      return JSON.parse(JSON.stringify(this.$store.getters.allReminders))
    }
  },
  async mounted() {
    await this.$store.dispatch('getAllRemindersFromAPI')
    if (this.$route.query.reminderId) {
      this.selectReminderFromRouter(this.$route.query.reminderId)
    }
  },
  methods: {
    selectReminderFromRouter(reminderId) {
      const _reminder = this.reminders.find((item) => {
        return item.id === reminderId
      })
      if (_reminder) {
        this.selectedReminder = _reminder
        this.detailModal = true
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: "Reminder not found.",
          color: "error"
        })
      }
    },
    openDetailsModal(reminder) {
      this.$router.push({ path: this.$route.path, query: { reminderId: reminder.id } })
    },
    closeDetailsModal() {
      this.$router.push({ path: this.$route.path })
      this.selectedReminder = {}
      this.detailModal = false
    },
    openEditModal(reminder) {
      this.selectedReminder = reminder
      this.createUpdateModal = true
      this.isUpdate = true
    },
    translateCronExpression(exp) {
      return cronstrue.toString(exp)
    },
    async deleteReminder(reminder) {
      let modalTitle = "Delete!!"
      let modalSubtitle = "Are you sure about delete?"
      this.loading = true
      if (
        await this.$refs.confirm.open({
          title: modalTitle,
          description: modalSubtitle,
        })
      ) {
        const response = await this.$store.dispatch("deleteReminderAction", reminder.id);
        if (response && response.status === 206) {
          this.loading = false
          this.detailModal = false
        } else {
          this.loading = false
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      } else {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.reminderId'(newValue) {
      if (newValue) {
        this.selectReminderFromRouter(newValue)
      }
    }
  }
}
</script>

<style scoped>
.reminder-list {
  margin-top: 24px;
  margin-left: 5px;
  margin-right: 5px;
}

.reminder-content {
  cursor: pointer;
}
</style>