<template>
  <reminder-list />
</template>

<script>
import ReminderList from '../../components/reminder/ReminderList.vue';
export default {
  name: "ReminderListView",
  components: { ReminderList }
}
</script>